import React, { useState, useRef, useEffect } from 'react';

const Portfolio = () => {
  const [isTerminalView, setIsTerminalView] = useState(true);
  const [history, setHistory] = useState(['Welcome to eelayoubi terminal [Version 1.0.0]', 'Type "help" for available commands.']);
  const [currentCommand, setCurrentCommand] = useState('');
  const terminalRef = useRef(null);

  const experiences = [
    {
      title: "Technical Product Owner Consultant",
      company: "Ikea",
      location: "The Netherlands",
      timeframe: "June 2022 - Present",
      responsibilities: [
        `Leading the deployment and migration of a new WMS across IKEA's global network (700 stores), resulting in successful
        implementation in pilot stores`,
        `Developed a JavaScript-based command line tool leveraging the ChatGPT API, enabling engineers to query and execute
        commands directly within the terminal, enhancing productivity by eliminating the need for external searches`,
        `Integrated AI capabilities (Ollama, PrivateGPT, and RAG) into Confluence, hosted on GCP , to improve information retrieval
        and search accuracy across Confluence spaces and Github documentations, streamlining team workflows`,
        `Collaborated with stakeholders to define technical requirements, led architecture design workshops, and successfully
        implemented a production-ready solution that met business needs and achieved measurable impact`,
        `Developed an automated infrastructure provisioning process by using Terraform and integrating it into GitHub Workflows
        (Actions), enabling full environment setup in 30 minutes and facilitating quick recovery from unexpected issues`,
        `Enhancing system reliability and response times by establishing extensive monitoring solutions for both infrastructure and
        applications, supporting a 24/7 on-call rotation`,
        `Implementing a robust Disaster Recovery plan, achieving an RTO and RPO of 2 hours, by designing and deploying`
      ]
    },
    {
      title: "Senior Cloud Engineer Consultant",
      company: "Ikea",
      location: "The Netherlands",
      timeframe: "November 2021 - 2022",
      responsibilities: [
        `Led the implementation of a self-hosted shared CI/CD pipeline, reducing build times by 30%, increasing team efficiency by 20%`,
        `Developed a cloud abstraction tool using Terraform CDK and JavaScript, enhancing deployment efficiency by 60% by creating
        a system that generates infrastructure from YAML files`,
        `Built a status page provisioner using Terraform CDK, Terraform modules, Ilert, and JavaScript, enabling teams to create
        custom status pages and link critical services, improving SLA visibility and service monitoring across the department`
      ]
    },
    {
      title: "Senior Cloud Engineer Consultant",
      company: "AKQA",
      location: "The Netherlands",
      timeframe: "July 2021 - November 2021",
      responsibilities: [
        `Refactored Node.js applications and libraries responsible for inter-database synchronization, accelerating data processing by
        55%, significantly improving Dr. Oetker's data management efficiency during their AWS migration`,
        `Enhanced Dr. Oetker's website search functionality by 40% and reduced user wait time by 2 seconds by implementing
        Algolia, replacing the existing search tool and improving overall user experience` ,
        `Boosted deployment speed by 60% by architecting and implementing robust CI/CD pipelines, streamlining Dr. Oetker's
        development and release processes as part of their digital transformation strategy`
      ]
    },
    {
      title: "Technical Lead",
      company: "Air France / KLM",
      location: "The Netherlands",
      timeframe: "September 2015 - July 2021",
      responsibilities: [
        `Led frontend & backend development to unify Air France - KLM web applications, working with JavaScript, Angular, GraphQL and
        Node.js to build the profile, check-in and trip components`,
        `Migrated web applications to Kubernetes, optimized application performance using Redis for caching, and improved response
        times by 30% using CDN cashing and edge computing`,
        `Led frontend and enablement teams. Mentored interns, converting two to full-time roles post-internship`
      ]
    }
  ];

  const skills = {
    languages: ["JavaScript", "Python", "Node.js", "Graphql", "Bash", "Terraform", "Terraform CDK", "Ansible"],
    cloud: ["Amazon Web Service (AWS)", "Google Cloud Platform (GCP)", "Openshift"],
    infrastructure: ["Linux", "Windows", "VMs", "Load Balancing", "databases", "storages", "DNS", "pub/sub", "Kubernetes", "Docker"],
    CI_CD: ["Github", "Github actions", "Github Workflows", "Bamboo", "Gitlab"],
    observability: ["Grafana Stack (prometheus, Loki, mimir)", "Splunk", "SignalFx", "Catchpoint", "ILert"],
    ML: ["Supervised Learning (Regression, Classification)", "Advanced Algorithms (Neural Networks, Decision Trees)", "Unsupervised Learning (Recommenders, Reinforcement Learning)", "RAG"],
    Architecture: ["Microservices", "SOAP", "Rest APIs", "Containerization", "Kubernetes", "Event Mesh", "HA", "DR"]
  };

  const projects = [
    {
      name: "AI-Enhanced Information Searchability in Confluence",
      description: "Improved information accuracy and searchability in Confluence by integrating AI capabilities through Ollama, PrivateGPT, and RAG model",
      technologies: ["Python", "PrivateGPT", "React", "RAG", "GCP", "Ollama"]
    },
    {
      name: "AI-Powered Command Line Tool with ChatGPT Completions API",
      description: "Command Line tool to query specific commands directly within the terminal, receiving tailored information and suggestions",
      technologies: ["Node.js", "GPT", "Bash"]
    }
  ];

  const formatExperience = (exp) => (
    <div className="mb-4">
      <div>
        <span className="text-cyan-400">{exp.title}</span>
        <span className="text-white"> | </span>
        <span className="text-yellow-400">{exp.company}</span>
        <span className="text-white">, {exp.location} | {exp.timeframe}</span>
      </div>
      {exp.responsibilities.map((resp, index) => (
        <div key={index} className="ml-4 text-green-500">• {resp}</div>
      ))}
    </div>
  );

  const formatSkills = (category, items) => (
    <div className="ml-4 text-green-500">
      <span className="text-cyan-400">{category.charAt(0).toUpperCase() + category.slice(1)}</span>: {items.join(', ')}
    </div>
  );

  const commands = {
    help: () => [
      'Available commands:',
      'experience - list professional experience',
      'skills - List technical skills',
      'projects - List personal projects',
      'contact - Show contact information',
      'clear - Clear terminal',
      'help - Show this help message',
      'toggle - Switch to traditional view'
    ],

    experience: () => [
      '🚀 Professional Experience:',
      '',
      ...experiences.map((exp, index) => (
        <div key={index}>{formatExperience(exp)}</div>
      ))
    ],

    skills: () => [
      '💻 Technical Skills:',
      '',
      ...Object.entries(skills).map(([category, items]) => formatSkills(category, items))
    ],

    projects: () => [
      '🛠️ Personal Projects:',
      '',
      ...projects.map(project =>
        `${project.name} - ${project.description}\nTechnologies: ${project.technologies.join(', ')}`
      )
    ],

    contact: () => [
      '📫 Contact Information:',
      '',
      'Email: ',
      'LinkedIn: https://www.linkedin.com/in/ezzeddine-62535397/',
      'GitHub: https://github.com/eelayoubi'
    ],

    toggle: () => {
      setIsTerminalView(false);
      return ['Switching to traditional view...'];
    },

    clear: () => {
      setHistory([]);
      return [];
    }
  };

  const handleCommand = (input) => {
    const args = input.trim().toLowerCase().split(' ');
    const command = args[0];
    // const subCommand = args[1];

    if (!input) {
      return [];
    }

    if (commands[command]) {
      return commands[command]();
    }

    return [`Command not found: ${input}. Type "help" for available commands.`];
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const output = handleCommand(currentCommand);
      setHistory(prev => [...prev, `$ ${currentCommand}`, ...output]);
      setCurrentCommand('');
    }
  };

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [history]);

  const TraditionalView = () => (
    <div className="bg-gray-900 text-white">
      {/* Introduction Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600">
        <div className="container mx-auto px-4 py-16">
          <div className="max-w-3xl">
            <h1 className="text-4xl font-bold mb-4">Hi, I'm Ezzeddine 👋</h1>
            <p className="text-xl mb-6">
              Senior Software Engineer | Open Source Enthusiast | Tech Explorer | Jack Of All Trades
            </p>
            <p className="text-lg opacity-90">
              As an engineer, I enjoy building solutions that solve real problems, or maybe interesting problems.
            </p>
          </div>
        </div>
      </div>

      {/* Experience Section */}
      <section className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-8 text-cyan-400">Experience</h2>
        {experiences.map((exp, index) => (
          <div key={index} className="mb-8">
            <div className="mb-2">
              <span className="text-xl text-cyan-400">{exp.title}</span>
              <span className="text-white"> | </span>
              <span className="text-yellow-400">{exp.company}</span>
              <span className="text-gray-300">, {exp.location} | {exp.timeframe}</span>
            </div>
            <ul className="list-disc list-inside text-gray-300 ml-4">
              {exp.responsibilities.map((resp, idx) => (
                <li key={idx} className="mb-1">{resp}</li>
              ))}
            </ul>
          </div>
        ))}
      </section>

      {/* Skills Section */}
      <section className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-8 text-cyan-400">Skills</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {Object.entries(skills).map(([category, items]) => (
            <div key={category} className="bg-gray-700 rounded-lg p-6">
              <h3 className="text-xl font-bold mb-4 text-yellow-400 capitalize">{category}</h3>
              <div className="flex flex-wrap gap-2">
                {items.map((item, idx) => (
                  <span key={idx} className="bg-gray-600 px-3 py-1 rounded-full text-sm">
                    {item}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Projects Section */}
      <section className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-8 text-cyan-400">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <div key={index} className="bg-gray-800 rounded-lg p-6">
              <h3 className="text-xl font-bold mb-2 text-yellow-400">{project.name}</h3>
              <p className="text-gray-300 mb-4">{project.description}</p>
              <div className="flex flex-wrap gap-2">
                {project.technologies.map((tech, idx) => (
                  <span key={idx} className="bg-gray-700 px-2 py-1 rounded-full text-xs">
                    {tech}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-900">
      <button
        onClick={() => setIsTerminalView(!isTerminalView)}
        className="fixed top-4 right-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-2 rounded-full 
                   shadow-lg hover:from-blue-600 hover:to-purple-600 transition-all duration-300 z-50"
      >
        Switch to {isTerminalView ? 'Traditional' : 'Terminal'} View
      </button>

      {isTerminalView ? (
        <div>
          {/* Introduction Section */}
          <div className="bg-gradient-to-r from-blue-600 to-purple-600 text-white">
            <div className="container mx-auto px-4 py-16">
              <div className="max-w-3xl">
                <h1 className="text-4xl font-bold mb-4">Hi, I'm Ezzeddine 👋</h1>
                <p className="text-xl mb-6">
                  Senior Software Engineer | Open Source Enthusiast | Tech Explorer | Jack Of All Trades
                </p>
                <p className="text-lg opacity-90">
                  Welcome to my interactive terminal portfolio! Use the terminal below to explore my experience,
                  projects, and more. Type "help" to see available commands.
                </p>
              </div>
            </div>
          </div>

          {/* Terminal Section */}
          <div className="container mx-auto px-4 py-8">
            <div className="bg-black rounded-lg overflow-hidden shadow-2xl">
              <div className="bg-gray-800 px-4 py-2 flex items-center">
                <div className="flex space-x-2">
                  <div className="w-3 h-3 rounded-full bg-red-500"></div>
                  <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                  <div className="w-3 h-3 rounded-full bg-green-500"></div>
                </div>
                <div className="ml-4 text-white opacity-75 text-sm">
                  eelayoubi@portfolio:~
                </div>
              </div>

              <div
                ref={terminalRef}
                className="h-96 p-4 font-mono overflow-y-auto"
              >
                {history.map((line, i) => (
                  <div key={i} className="mb-1">
                    {typeof line === 'string' ? (
                      <span className="text-green-500">{line}</span>
                    ) : (
                      line
                    )}
                  </div>
                ))}
                <div className="flex">
                  <span className="text-green-500 mr-2">$</span>
                  <input
                    type="text"
                    value={currentCommand}
                    onChange={(e) => setCurrentCommand(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="flex-1 bg-transparent text-green-500 font-mono focus:outline-none"
                    autoFocus
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <TraditionalView />
      )}
    </div>
  );
};

export default Portfolio;